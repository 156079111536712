.navBar-text {
  font-size: 20px;
  font-family: "Times New Roman", Times, serif;
  font-weight: 500;
  &:hover {
    color: rgb(255, 102, 0);
  }
}
#basic-nav-dropdown {
  font-size: 20px;
  font-family: "Times New Roman", Times, serif;
  font-weight: 500;
  &:hover {
    color: rgb(255, 102, 0);
  }
}
