.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}
.contact-heading {
  text-align: center;
  color: rgb(255, 165, 0);
  font-weight: 500;
  font-size: 34px;
}
.contact-address {
  color: rgb(255, 165, 0);
  font-weight: 500;
  font-size: 24px;
}
.contact-image {
  height: 300px;
}
