.gallery-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.list-of-images {
  margin: 10px;
  list-style: none;
  border-radius: 8px;
  box-shadow: 4px 10px 5px 1px gray;
}
.gallery-image {
  height: 250px;
  border-radius: 5px;
}
